<template>
    <el-row class="header">
        <el-col :span="12">
            <el-form
                ref="ruleFormRef"
                status-icon
                class="demo-ruleForm"
                @submit.prevent="searchMenu"
            >
                <img src="@/assets/icons/search.png" alt="">
                <el-form-item>
                    <el-input type="text" v-model="keySearch" placeholder="Search" @input="searchMenu"/>
                </el-form-item>
            </el-form>
            <ul class="ul_search" :class="{'d-none' : keySearch == ''}">
                <li v-for="(item, index) in list_menu_fil" :key="index">
                    <router-link :to="item.url" @click="keySearch = ''">{{ item.name }}</router-link>
                </li>
            </ul>
        </el-col>
        <el-col :span="12" class="text-end">
            <!-- <el-badge class="item">
                <el-button><img src="@/assets/icons/calendar.png" alt=""></el-button>
            </el-badge>
            <el-badge :value="2" class="item">
                <el-button><img src="@/assets/icons/envelope.png" alt=""></el-button>
            </el-badge> -->
            <!-- <el-badge :value="2" class="item">
                <el-button><img src="@/assets/icons/bell.png" alt=""></el-button>
            </el-badge> -->

            <el-dropdown trigger="click" popper-class="menu_noti">
                <el-badge :value="countNoti" :max="9" class="item">
                    <el-button><img src="@/assets/icons/bell.png" alt=""></el-button>
                </el-badge>
                <template #dropdown>
                    <el-dropdown-menu>
                        <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                            <div class="d-flex align-items-center">
                                <img src="@/assets/icons/noti_ico.png" alt="" style="width: 27px; height: 27px;">
                                <span style="font-size: 18px; font-weight: 600; color: #515151; margin-left: 11px;">Thông báo</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span style="font-size: 15px; color: #515151; margin-right: 5px;">Chưa đọc</span>
                                <el-switch
                                    v-model="read_noti"
                                    size="small"
                                    @change="filterNoti"
                                />
                            </div>
                        </div>
                        <el-tabs class="demo-tabs" v-model="tab_active" @tab-click="handleClick">
                            <el-tab-pane label="Tất cả" name="all">
                                <div class="list-item" @scrollend="getMore" ref="listNoti">
                                    <router-link to="" class="noti_item" v-for="(item, index) in list_noti" :key="index" :class="{'active' :item.view_status == 0}" @click="updateView(item), redirectNoti(item)">
                                        <img src="@/assets/icons/noti_item.png" alt="">
                                        <div class="info" style="width: 100%;">
                                            <p class="ttl">{{ item.title }}</p>
                                            <p class="date">Thời gian: <span>{{ formateDate(item.push_at) }}</span></p>
                                        </div>
                                    </router-link>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Hỗ trợ" name="support">
                                <div class="list-item" @scrollend="getMore" ref="listNoti">
                                    <router-link :to="'/ho-tro/chi-tiet/' + encode(item.support_id)" class="noti_item" v-for="(item, index) in list_noti" :key="index" :class="{'active' :item.view_status == 0}" @click="updateView(item)">
                                        <img src="@/assets/icons/noti_item.png" alt="">
                                        <div class="info" style="width: 100%;">
                                            <p class="ttl">{{ item.title }}</p>
                                            <p class="date">Thời gian: <span>{{ formateDate(item.push_at) }}</span></p>
                                        </div>
                                    </router-link>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Tin đăng BĐS" name="real_estate">
                                <div class="list-item" @scrollend="getMore" ref="listNoti">
                                    <router-link :to="'/quan-ly-bds/duyet-tin/chi-tiet/' + encode(item.real_estate_id)" class="noti_item" v-for="(item, index) in list_noti" :key="index" :class="{'active' :item.view_status == 0}" @click="updateView(item)">
                                        <img src="@/assets/icons/noti_item.png" alt="">
                                        <div class="info" style="width: 100%;">
                                            <p class="ttl">{{ item.title }}</p>
                                            <p class="date">Thời gian: <span>{{ formateDate(item.push_at) }}</span></p>
                                        </div>
                                    </router-link>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Khách hàng" name="member">
                                <div class="list-item" @scrollend="getMore" ref="listNoti">
                                    <router-link :to="'/quan-ly-khach-hang/xac-minh-khach-hang/chi-tiet/' + encode(item.member_id)" class="noti_item" v-for="(item, index) in list_noti" :key="index" :class="{'active' :item.view_status == 0}" @click="updateView(item)">
                                        <img src="@/assets/icons/noti_item.png" alt="">
                                        <div class="info" style="width: 100%;">
                                            <p class="ttl">{{ item.title }}</p>
                                            <p class="date">Thời gian: <span>{{ formateDate(item.push_at) }}</span></p>
                                        </div>
                                    </router-link>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Công việc" name="job">
                                <div class="list-item" @scrollend="getMore" ref="listNoti">
                                    <router-link to="" class="noti_item" v-for="(item, index) in list_noti" :key="index" :class="{'active' :item.view_status == 0}" @click="updateView(item)">
                                        <img src="@/assets/icons/noti_item.png" alt="">
                                        <div class="info" style="width: 100%;">
                                            <p class="ttl">{{ item.title }}</p>
                                            <p class="date">Thời gian: <span>{{ formateDate(item.push_at) }}</span></p>
                                        </div>
                                    </router-link>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    <div class="d-flex align-items-center">
                        <img v-if="adInfo" :src="$storage + adInfo.avatar" alt="" @error="handleError" style="width: 45px; height: 45px; border-radius: 116px; object-fit: cover;">
                        <div class="text-start ms-2 me-4" :class="{'none_txt' : $route.path.includes('/cong-viec/')}">
                            <p class="mb-0" v-if="adInfo">{{ adInfo.full_name }}</p>
                            <p class="mb-0" v-if="adInfo">{{ adInfo.role_name }}</p>
                        </div>
                        <el-icon class="el-icon--right">
                            <arrow-down />
                        </el-icon>
                    </div>
                </span>

                <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item v-if="adInfo" @click="$router.push('/trang-ca-nhan/' + encode(adInfo.id))">Thông tin cá nhân</el-dropdown-item>
                    <el-dropdown-item @click="logOut">Đăng xuất</el-dropdown-item>
                </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
    </el-row>
</template>
<script>
import { ArrowDown } from '@element-plus/icons-vue'
import { mapActions, mapGetters } from 'vuex';
import { notification } from '../../firebase'
export default {
    components: {
        ArrowDown
    },
    data() {
        return {
            keySearch: '',
            list_menu: [
                {name: 'Danh sách chi nhánh', url: '/quan-ly-chi-nhanh'},
                {name: 'Tạo mới chi nhánh', url: '/quan-ly-chi-nhanh/them-moi'},
                {name: 'Danh mục bất động sản', url: '/quan-ly-bds/danh-muc'},
                {name: 'Tạo mới danh mục bất động sản', url: '/quan-ly-bds/danh-muc/them-moi'},
                {name: 'Danh mục dự án', url: '/quan-ly-bds/danh-muc-du-an'},
                {name: 'Duyệt tin bất động sản', url: '/quan-ly-bds/duyet-tin-bds'},
                {name: 'Danh sách tin đăng bất động sản', url: '/quan-ly-bds/danh-sach-tin-bds'},
                {name: 'Tạo mới bất động sản', url: '/quan-ly-bds/danh-sach/tao-moi'},
                {name: 'Dự án bất động sản', url: '/quan-ly-bds/du-an'},
                {name: 'Tạo mới dự án bất động sản', url: '/quan-ly-bds/du-an/them-moi'},
                {name: 'Kho bất động sản', url: '/quan-ly-bds/kho-bds'},
                {name: 'Thiết lập tin đăng', url: '/quan-ly-bds/thiet-lap-tin'},
                {name: 'Thêm mới nhân viên', url: '/quan-ly-nhan-vien/them-moi'},
                {name: 'Danh sách nhân viên', url: '/quan-ly-nhan-vien/danh-sach'},
                {name: 'Duyệt nhân viên Sale', url: '/quan-ly-nhan-vien/duyet-nhan-vien'},
                {name: 'Phân quyền nhân viên', url: '/quan-ly-nhan-vien/phan-quyen'},
                {name: 'Danh sách chức năng', url: '/quan-ly-nhan-vien/phan-quyen/danh-sach-chuc-nang'},
                {name: 'Quản lý công việc', url: '/cong-viec/quan-ly-cong-viec'},
                {name: 'Xác minh khách hàng', url: '/quan-ly-khach-hang/xac-minh-khach-hang'},
                {name: 'Danh sách khách hàng', url: '/quan-ly-khach-hang/danh-sach'},
                {name: 'Tạo mới tin tức', url: '/quan-ly-tin-tuc/tao-moi'},
                {name: 'Danh sách tin tức', url: '/quan-ly-tin-tuc/danh-sach'},
                {name: 'Quản lý tài chính', url: '/quan-ly-tai-chinh/lich-su-giao-dich'},
                {name: 'Khách hàng quản lý', url: '/cong-viec/khach-hang-quan-ly'},
                {name: 'Tạo mới khách hàng', url: '/cong-viec/khach-hang-quan-ly/tao-moi'},
                {name: 'Báo cáo chung', url: '/'},
                {name: 'Quản lý chung', url: '/he-thong/quan-ly-chung'},
                {name: 'Quản lý thông báo', url: '/he-thong/quan-ly-thong-bao'},
                {name: 'Quy chế và điều khoản', url: '/he-thong/quy-che-dieu-khoan'},
                {name: 'Chương trình - sự kiện', url: '/he-thong/chuong-trinh'},
            ],
            list_menu_fil: [],
            tab_active: 'all',
            read_noti: false,
            type_noti: '',
            view_status: '',
            per_page: 10,
            list_noti: [],
            countNoti: '',
            total: ''
        }
    },
    computed: {
        ...mapGetters(['adInfo']),
        getDeviceToken() {
            return notification
        },
    },
    mounted() {
        if(localStorage.getItem("ad_tk")) {
            this.getDataNoti()
            this.getUserCurrent()
        }
    },
    methods: {
        ...mapActions(['logoutAdmin', 'loginAdmin']),
        async logOut() {
            this.startLoading();
            await this.$axios.post('/auth/signout',{} ,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.logoutAdmin()
                this.$router.push('/dang-nhap')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        searchMenu() {
            this.list_menu_fil = this.list_menu.filter(item => {
                return item.name.toLowerCase().includes(this.keySearch.toLowerCase());
            });
        },
        handleClick(tab) {
            if(tab.paneName == 'all') {
                this.type_noti = ''
            } else {
                this.type_noti = tab.paneName
            }
            this.getDataNoti()
        },
        async getDataNoti() {
            // this.startLoading();
            await this.$axios.get('/notification/getListNotificationForAdmin', {
                params: {
                    type: this.type_noti,
                    order_by: 'latest',
                    view_status: this.view_status,
                    page: 1,
                    per_page: this.per_page
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.list_noti = res.data.result
                this.countNoti = res.data.count_unread
                this.total = res.data.pagination.total_element
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            // this.endLoading();
        },
        getMore() {
            const container = this.$refs.listNoti;
            const scrollHeight = container.scrollHeight;
            const scrollTop = container.scrollTop;
            const clientHeight = container.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight) {
                if(this.per_page < this.total) {
                    this.per_page += 10
                    this.getDataNoti()
                }
            }
        },
        filterNoti() {
            if(this.read_noti) {
                this.view_status = 0
            } else {
                this.view_status = ''
            }
            this.getDataNoti()
        },
        async updateView(item) {
            this.startLoading();
            await this.$axios.put('/notification/readNotification',{id: item.id}, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDataNoti()
                document.getElementsByClassName('el-dropdown')[0].click()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        listenNoti() {
            let mouse = this 
            var noticeMe = this.getDeviceToken.notificate
            if (noticeMe) {
                noticeMe.onMessageHandler = function () {
                    mouse.getDataNoti()
                }
            }
        },
        redirectNoti(item) {
            if(item.type == 'support') {
                this.$router.push('/ho-tro/chi-tiet/' + this.encode(item.support_id))
            } else if(item.type == 'real_estate') {
                this.$router.push('/quan-ly-bds/danh-sach/chi-tiet/' + this.encode(item.real_estate_id))
            } else if(item.type == 'member') {
                this.$router.push('/quan-ly-khach-hang/xac-minh-khach-hang/chi-tiet/' + this.encode(item.member_id))
            } else if(item.type == 'transaction') {
                this.$router.push('/quan-ly-tai-chinh/giao-dich-member')
            } else if(item.type == 'event') {
                this.$router.push('/he-thong/chuong-trinh/danh-sach-trung-thuong/' + this.encode(item.event_id))
            }
        },
        async getUserCurrent() {
            await this.$axios.get("/auth/getUserProfile", {
                headers: {
                    'x-access-token': this.getToken()
                }
            })
            .then((res) => {
                this.$store.dispatch("loginAdmin", res.data);
            });
        },
    }
}
</script>

<style lang="css" scoped>
    .header {
        align-items: center;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-badge.item {
        margin-right: 32px;
    }

    .el-badge.item .el-button {
        border: none;
        background: none;
        padding-right: 0;
    }

    .el-dropdown-link p {
        color: #515151;
        font-size: 16px;
    }
    .el-dropdown-link p:last-child {
        color: #4A4C56;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    .ul_search {
        background: #fff;
        border-radius: 20px;
        padding: 10px;
        list-style: none;
        position: absolute;
        min-width: 400px;
        top: 42px;
        box-shadow: 0px 0px 2.11856px 0px rgba(0, 0, 0, 0.25);
        z-index: 10;
    }

    .ul_search a {
        color: #515151;
        text-decoration: none;
        font-weight: 500;
    }

    .ul_search li {
        padding: 5px;
    }

    .none_txt {
        display: none;
    }

    @media (min-width: 768px) {
        .none_txt {
            display: block;
        }
    }
</style>
<style lang="css">
    .header .el-input__wrapper,
    .header .el-input__wrapper:hover,
    .header .el-input__wrapper.is-focus {
        background: transparent;
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
    }

    .header .el-badge__content.is-fixed {
        top: 8px;
    }

    .header .el-dropdown-link:focus-visible {
        outline: none;
    }

    .menu_noti {
        border-radius: 15px !important;
    }

    .menu_noti .el-scrollbar {
        border-radius: 15px !important;
        padding: 15px;
        width: 533px;
    }

    .menu_noti .el-tabs__item {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
    }

    .menu_noti .el-tabs__item.is-active {
        color: #F13A3A;
    }

    .menu_noti .el-tabs__active-bar {
        background-color: #F13A3A;
    }

    .menu_noti .el-tabs__item:hover {
        color: #515151;
    }

    .noti_item {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #B4B4B4;
        margin-bottom: 10px;
    }

    .noti_item.active {
        background: #EFEFEF;
    }

    .noti_item img {
        width: 33px;
        height: 33px;
        margin-right: 29px;
    }
    
    .noti_item p {
        margin-bottom: 5px;
    }

    .noti_item p.date {
        color: #515151;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
        text-align: right;
    }

    .noti_item p.date span {
        color: #515151;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
    }

    .list-item {
        max-height: 400px;
        overflow: auto;
    }

    .list-item::-webkit-scrollbar {
        width: 0;
    }

    .el-table .cell {
        word-break: break-word !important;
    }
</style>