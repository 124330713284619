<template>
    <div class="wp-confirm">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết tin đăng</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span v-if="!check">Duyệt tin bất động sản</span>
                    <span v-else>Danh sách tin đăng</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết tin đăng</span>
                </div>
            </div>
            <div class="wp-btn">
                <span class="status_new" v-if="check">Trạng thái tin 
                    <span v-if="dataDetail.news_status == 2 && dataDetail.is_delete == 0">Còn hạn</span>
                    <span class="expired" v-else-if="dataDetail.news_status == 3 && dataDetail.is_delete == 0">Hết hạn</span>
                    <span class="expired" v-else-if="dataDetail.is_delete == 1">Đã xóa</span>
                </span>
                <span class="show_web" v-if="check">
                    <!-- <span v-if="dataDetail.view_status == 0">Hiển thị trên web</span> -->
                    <!-- <span v-else>Ẩn tin trên web</span> -->
                    <span>Hiển thị trên web</span>
                    <el-switch
                        v-model="dataDetail.view_status"
                        :active-value="1"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                        @change="updateViewStatus"
                        :disabled="dataDetail.news_status != 2 || dataDetail.is_delete == 1"
                    />
                    <!-- :disabled="dataDetail.news_status != 2" -->
                </span>
                <button @click="$router.push('/quan-ly-bds/danh-sach/chinh-sua/' + encode(id))" :disabled="dataDetail.is_delete == 1">Chỉnh sửa</button>
                <button class="btn-rej" v-if="!check" @click="rejectVisible = true"><img src="@/assets/icons/cross.png" alt="" style="filter: brightness(0.5);"> Từ chối tin đăng</button>
                <button class="btn_approve" :disabled="drop_txt == 'Chọn chi nhánh quản lý' && dataDetail.poster_type != 1" v-if="!check" @click="openDialog">Phê duyệt đăng tin</button>
                <button class="btn_delete" @click="dialogDel = true" v-if="check" :disabled="dataDetail.is_delete == 1">Xóa tin đăng</button>
            </div>
        </div>
        <el-row>
            <el-col :span="18">
                <div class="detail">
                    <h5>Thông tin chi tiết bất động sản</h5>
                    <Detail :data="dataDetail"/>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="dropdown dropdown--agency" v-if="!check && dataDetail.poster_type != 1">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ drop_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chi nhánh</p>
                        <li v-for="item in list_agency.result" :key="item.id"><a class="dropdown-item" href="#" @click="getName(item.agency_name, item.id)">{{ item.agency_name }}</a></li>
                    </ul>
                </div>

                <div class="status_bds" v-if="check">
                    <p style="color: #686868; font-size: 14px; font-weight: 500; margin-bottom: 5px;">Trạng thái bất động sản</p>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="status" data-bs-toggle="dropdown" aria-expanded="false" :disabled="dataDetail.is_delete == 1">
                            <span class="txt--green" v-if="dataDetail.real_estate_status == 1">Mở bán</span>
                            <span class="txt--red" v-else-if="dataDetail.real_estate_status == 2">Đã bán</span>
                            <span class="txt--red" v-else>Đang giao dịch</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="status">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Trạng thái</p>
                            <li>
                                <a class="dropdown-item txt--green" href="#" @click="updateRealEstateStatus(1)">Mở bán</a>
                            </li>
                            <!-- <li>
                                <a class="dropdown-item txt--red" href="#" @click="updateRealEstateStatus(2)">Đã bán</a>
                            </li> -->
                            <li>
                                <a class="dropdown-item txt--red" href="#" @click="updateRealEstateStatus(3)">Đang giao dịch</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="bid" v-if="dataDetail.type == 1">
                    <button type="button" @click="$router.push('/quan-ly-bds/danh-sach-tra-gia/' + encode(id))">
                        <img src="@/assets/icons/auction.png" alt="">
                        Danh sách trả giá bất động sản
                    </button>
                    <span>(Số lượt giá đã trả: {{ dataDetail.count_payment }})</span>
                </div>

                <div class="manager-info" v-if="check && dataDetail.poster_type != 1">
                    <h5 style="margin-bottom: 22px;">Thông tin quản lý</h5>
                    <p>Chi nhánh quản lý</p>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle agency" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false" :disabled="dataDetail.is_delete == 1">
                            {{ dataDetail.agency_name }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="agency">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chi nhánh</p>
                            <li v-for="(item, index) in list_agency.result" :key="index">
                                <a class="dropdown-item" href="#" @click="updateRealEstateAgency(item.id)">{{ item.agency_name }}</a>
                            </li>
                        </ul>
                    </div>

                    <p>Loại hợp đồng môi giới bất động sản </p>
                    <div class="wp-btn-type">
                        <button :class="{'active' : dataDetail.contract_type == 1}" @click="updateContractType(1)" :disabled="dataDetail.is_delete == 1">Ký trực tiếp</button>
                        <button :class="{'active' : dataDetail.contract_type == 2}" @click="updateContractType(2)" :disabled="dataDetail.is_delete == 1">Liên kết</button>
                    </div>

                    <div style="margin: 15px 0;">
                        <p style="font-size: 16px; font-weight: 500; color: #515151;">File hợp đồng môi giới</p>
                        <input type="file" ref="file_input" multiple accept="image/jpeg,image/jpg,image/png,application/pdf" style="display: none;" @change="updateContract">
                        <div class="btn_file" @click="$refs.file_input.click()" :class="{'disabled--block' : dataDetail.is_delete == 1}">
                            <button>Chọn file</button>
                            <span>Tải lên file giấy tờ</span>
                        </div>
                        <ul class="list_file">
                            <li v-for="(item, index) in dataDetail.contract" :key="index">
                                <Fancybox>
                                    <a v-if="item" data-fancybox="gallery" :href="$storage + item.path">
                                        <img src="@/assets/icons/Note.png" alt="">
                                        {{ item.file_name.substring(item.file_name.lastIndexOf('-') + 1) }}
                                    </a>
                                </Fancybox>
                            </li>
                        </ul>
                    </div>

                    <p>Hoa hồng/sản phẩm</p>
                    <div style="position: relative;">
                        <el-input type="text" v-model="dataDetail.commission" class="input_com" maxlength="7" placeholder="Nhập % hoa hồng" @input="calculateCommission('price')"/>
                        <span style="position: absolute; right: 5%; top: 10px; font-weight: 500;">%</span>
                    </div>

                    <p>Hoa hồng cho sale/doanh thu</p>
                    <div style="position: relative;">
                        <el-input type="text" v-model="dataDetail.sale_commission" class="input_com" maxlength="7" placeholder="Nhập % hoa hồng" @input="dataDetail.sale_commission = dataDetail.sale_commission.replace(/[^\d.]/g, '')"/>
                        <span style="position: absolute; right: 5%; top: 10px; font-weight: 500;">%</span>
                    </div>
                    <p class="mb-3" v-if="dataDetail.unit_id == 2 || dataDetail.unit_id == 4">Số tiền nhận: 
                        <span style="font-weight: 600;" v-if="dataDetail.unit_id == 2">{{ parseInt(dataDetail.price * (dataDetail.sale_commission / 100)).toLocaleString('vi') }} VNĐ</span>
                        <span style="font-weight: 600;" v-else-if="dataDetail.unit_id == 4">{{ parseInt((dataDetail.price * dataDetail.acreage) * (dataDetail.sale_commission / 100)).toLocaleString('vi') }} VNĐ</span>
                    </p>

                    <p>Hoa hồng/sản phẩm</p>
                    <div style="position: relative;">
                        <el-input type="text" v-model="price_commission" maxlength="15" class="input_com" placeholder="Nhập tiền hoa hồng" @input="calculateCommission('commission')"/>
                        <span style="position: absolute; right: 5%; top: 10px; font-weight: 500;">VNĐ</span>
                    </div>

                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="broker" data-bs-toggle="dropdown" aria-expanded="false" :disabled="dataDetail.is_delete == 1" style="height: 100%; box-shadow: none; margin-bottom: 15px;">
                            <div class="user user--manager d-flex align-items-center" style="margin-bottom: 0;">
                                <img src="@/assets/icons/add_user.svg" alt="" v-if="!dataDetail.broker_avatar">
                                <img :src="$storage + dataDetail.broker_avatar" alt="" v-else>
                                <div style="text-align: left;">
                                    <p style="color: #ABABAB;">Người ký hợp đồng môi giới</p>
                                    <p v-if="!dataDetail.broker_name">...................</p>
                                    <p v-else>{{ dataDetail.broker_name }}</p>
                                </div>
                            </div>
                        </button>
                        <ul class="dropdown-menu add_user" aria-labelledby="broker">
                            <el-form
                                ref="formLogin"
                                style="margin-bottom: 10px;"
                            >
                                <el-form-item>
                                    <el-input v-model="keySearch" type="text" placeholder="Search" />
                                </el-form-item>
                            </el-form>
                            <div class="wp-user">
                                <li v-for="(item, index) in filteredItems" :key="index" @click="updateBroker(item)">
                                    <a class="dropdown-item" href="#" style="justify-content: flex-start;">
                                        <img :src="$storage + item.avatar" alt="">
                                        <div>
                                            <p>{{ item.full_name }}</p>
                                            <p>{{ item.user_code }}</p>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </ul>
                    </div>

                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="broker" data-bs-toggle="dropdown" aria-expanded="false" :disabled="dataDetail.is_delete == 1" style="height: 100%; box-shadow: none; margin-bottom: 0">
                            <div class="user user--manager d-flex align-items-center" style="margin-bottom: 0;">
                                <img src="@/assets/icons/add_user.svg" alt="" v-if="!dataDetail.sale_avatar">
                                <img :src="$storage + dataDetail.sale_avatar" alt="" v-else>
                                <div style="text-align: left;">
                                    <p style="color: #ABABAB;">Sale quản lý</p>
                                    <p v-if="!dataDetail.sale_name">...................</p>
                                    <p v-else>{{ dataDetail.sale_name }}</p>
                                </div>
                            </div>
                        </button>
                        <ul class="dropdown-menu add_user" aria-labelledby="broker">
                            <el-form
                                ref="formLogin"
                                style="margin-bottom: 10px;"
                            >
                                <el-form-item>
                                    <el-input v-model="keySearch" type="text" placeholder="Search" />
                                </el-form-item>
                            </el-form>
                            <div class="wp-user">
                                <li v-for="(item, index) in filteredItems" :key="index" @click="updateSale(item)">
                                    <a class="dropdown-item" href="#" style="justify-content: flex-start;">
                                        <img :src="$storage + item.avatar" alt="">
                                        <div>
                                            <p>{{ item.full_name }}</p>
                                            <p>{{ item.user_code }}</p>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </ul>
                    </div>

                    <button class="confirm" 
                        :disabled="!dataDetail.commission || !dataDetail.broker || !dataDetail.sale || dataDetail.is_delete == 1"
                        @click="addStaff"
                        v-if="dataDetail.news_status == 1"
                    >
                        Xác nhận chọn quản lý
                    </button>

                    <button class="confirm" 
                        :disabled="!dataDetail.commission || dataDetail.is_delete == 1"
                        @click="updateCommission"
                        v-else
                    >
                        Lưu chỉnh sửa
                    </button>
                    <button class="view_bds" @click="$router.push('/quan-ly-bds/kho-bds/chi-tiet/' + encode(id))">Xem bất động sản trong kho >></button>
                </div>

                <div class="law-info">
                    <h5>Thông tin pháp lý</h5>
                    <div class="user d-flex align-items-center">
                        <img :src="$storage + dataDetail.poster_avatar" alt="" @error="handleError">
                        <div>
                            <p style="color: #ABABAB;">Bất động sản được đăng bởi tài khoản</p>
                            <p>{{ dataDetail.poster_name }}</p>
                        </div>
                    </div>
                    <p>Loại pháp lý</p>
                    <p class="info_txt">{{ dataDetail.juridical_name }}</p>
                    <p>File giấy tờ khách hàng</p>
                    <ul>
                        <li v-for="(item, index) in listFile" :key="index">
                            <Fancybox>
                                <a v-if="item" data-fancybox="gallery" :href="$storage + item">
                                    <img src="@/assets/icons/Note.png" alt="">
                                    {{ item.substring(item.lastIndexOf('-') + 1) }}
                                </a>
                            </Fancybox>
                        </li>
                    </ul>
                </div>

                <div class="info">
                    <h5>Thông tin cơ bản</h5>
                    <p>Địa chỉ chi tiết bất động sản</p>
                    <p class="info_txt">{{ dataDetail.address }}, {{ dataDetail.ward_name }}, {{ dataDetail.district_name }}, {{ dataDetail.province_name }}</p>
                    <p>Loại hình</p>
                    <p class="info_txt" v-if="dataDetail.type == 1">Rao bán</p>
                    <p class="info_txt" v-else>Cho thuê</p>
                    <p>Danh mục bất động sản</p>
                    <p class="info_txt">{{ dataDetail.category_name }}</p>
                    <p>Ngày tạo tin</p>
                    <p class="info_txt">{{ formateDate(dataDetail.create_at) }}</p>
                </div>

                <div class="pay-info">
                    <h5 v-if="!check">Thông tin thanh toán</h5>
                    <h5 v-else>Thông tin tin đăng</h5>
                    <p>Hạng tin đăng</p>
                    <p class="info_txt">{{ dataDetail.rank_name }}</p>
                    <p>Thời gian đăng tin</p>
                    <p class="info_txt">{{ dataDetail.day }} ngày</p>
                    <p>Tổng tiền đăng tin</p>
                    <p class="info_txt">{{ parseInt(dataDetail.day * dataDetail.fee).toLocaleString('vi') }} đ</p>
                    <div v-if="check">
                        <p>Ngày bắt đầu</p>
                        <p class="info_txt">{{ formateDate(dataDetail.start_date) }}</p>
                    </div>
                    <div v-if="check">
                        <p>Ngày kết thúc</p>
                        <p class="info_txt">{{ formateDate(dataDetail.end_date) }}</p>
                    </div>
                </div>
                <div class="extend mt-3" v-if="dataDetail.news_status == 3 && dataDetail.is_delete != 1">
                    <button type="button" @click="$router.push('/quan-ly-bds/danh-sach/gia-han/' + encode(id))">
                        Gia hạn tin đăng
                    </button>
                </div>
            </el-col>
        </el-row>

        <img src="@/assets/icons/back_top_ico.png" alt="" class="backtop" @click="scrollTop" :class="{'d-none': this.hidden}">

        <el-dialog
            v-model="dialogDel"
            width="553"
            class="dialog_del"
            @close="dialogDel = false"
            align-center
        >
            <img src="@/assets/icons/notice.png" alt="">
            <p class="ttl">Xóa </p>
            <p>Bạn đang yêu cầu xóa bỏ tin đăng bất động sản <br>Hãy xác nhận</p>
            <div class="wp-btn" style="margin-top: 20px;">
                <button class="btn_cancel" @click="dialogDel = false">Hủy bỏ</button>
                <button class="btn_add" @click="deleteRealEstate">Xác nhận</button>
            </div>
        </el-dialog>
        
        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            :title="title"
            :content="content"
            :content_success="content_success"
        />
        <RejectDialog 
            :rejectVisible="rejectVisible"
            @handleCloseDialog="rejectDialog"
            @confirm="rejectNew"
            :title="'Từ chối yêu cầu đăng tin'"
            :content="'Bạn đã từ chối yêu cầu đăng tin bất động sản mới'"
            :customer="'người đăng'"
        />
    </div>
</template>

<script>
import Detail from '../components/detail_bds.vue'
import Dialog from '../../components/dialog.vue'
import RejectDialog from '../../components/rejectDialog.vue'
import Fancybox from '../../components/fancy.vue'
export default {
    components: {
        Detail,
        Dialog,
        RejectDialog,
        Fancybox
    },
    data() {
        return {
            drop_txt: 'Chọn chi nhánh quản lý',
            list_agency: [],
            status: true,
            check: false,
            dialogVisible: false,
            rejectVisible: false,
            successDialog: false,
            title: '',
            content: '',
            content_success: '',
            id: '',
            dataDetail: [],
            listFile: [],
            agency_id: '',
            dialogDel: false,
            hidden: '',
            keySearch: '',
            listEmploy: [],
            price_commission: ''
        }
    },
    computed: {
        filteredItems() {
            return this.listEmploy.filter(employ => employ.full_name.toLowerCase().includes(this.keySearch.toLowerCase()));
        },
    },
    mounted() {
        if(this.$route.path.includes('/quan-ly-bds/danh-sach/chi-tiet')) {
            this.check = true
        }
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
        this.getAgency()
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
        "$route": function (url) {
            if(url.path.includes('/quan-ly-bds/danh-sach/chi-tiet')) {
                if(url.params.id) {
                    this.id = this.decode(url.params.id)
                }
                this.getDetail();
            }
        },
    },
    methods: {
        getName(name, id) {
            this.drop_txt = name
            this.agency_id = id
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        rejectDialog(rejectStatus) {
            this.rejectVisible = rejectStatus;
        },
        async confirm(successDialog) {
            const data = {
                'id' : this.id,
                'agency_id': this.agency_id
            }
            this.startLoading();
            await this.$axios.put('/real_estate/approveRealEstate', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.successDialog = successDialog;
                this.showNotify("success", "Thành công", "Phê duyệt tin đăng thành công!");
                this.$router.push('/quan-ly-bds/duyet-tin-bds')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async rejectNew(reason) {
            const data = {
                'id': this.id,
                'reason': reason
            }
            this.startLoading();
            await this.$axios.put('/real_estate/rejectRealEstate', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Từ chối duyệt tin đăng thành công!");
                this.$router.push('/quan-ly-bds/duyet-tin-bds')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        openDialog() {
            this.dialogVisible = true
            this.title = 'Phê duyệt'
            this.content = 'Bạn đang yêu cầu phê duyệt tin đăng bất động sản'
            this.content_success = 'Bạn đã phê duyệt tin đăng thành công'
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/real_estate/getRealEstateDetailForAdmin',{
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.dataDetail = res.data
                if(this.dataDetail.agency_name) {
                    this.drop_txt = this.dataDetail.agency_name
                }
                if(this.dataDetail.agency_id) {
                    this.agency_id = this.dataDetail.agency_id
                }
                document.title = `Chi tiết - ${this.dataDetail.real_estate_name}`;
                this.listFile = res.data.juridical_file.path
                this.getEmploy(res.data.agency_id)
                if(this.dataDetail.commission && this.dataDetail.unit_id == 2) {
                    this.price_commission = ((this.dataDetail.price * this.dataDetail.commission)/100).toLocaleString('vi')
                } else if(this.dataDetail.commission &&  this.dataDetail.unit_id == 4) {
                    this.price_commission = (((this.dataDetail.price * this.dataDetail.acreage) * this.dataDetail.commission)/100).toLocaleString('vi')
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
        },
        async updateViewStatus() {
            if(!this.dataDetail.view_status) {
                this.dataDetail.view_status = 0
            }
            const data = {
                "id": this.id,
                "view_status": this.dataDetail.view_status
            }
            this.startLoading();
            await this.$axios.put('/real_estate/updateViewStatusRealEstate',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thay đổi trạng thái hiển thị thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateRealEstateStatus(params) {
            this.startLoading();
            const data = {
                "id": this.id,
                "real_estate_status": params
            }
            await this.$axios.put('/real_estate/updateRealEstateStatus',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thay đổi trạng thái thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateRealEstateAgency(agency_id) {
            this.startLoading();
            const data = {
                "id": this.id,
                "agency_id": agency_id
            }
            await this.$axios.put('/real_estate/updateRealEstateAgency',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thay đổi chi nhánh thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async deleteRealEstate() {
            const data = {
                'id' : this.id,
            }
            this.startLoading();
            await this.$axios.put('/real_estate/deleteRealEstate', data, {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tin đăng thành công!");
                this.$router.push('/quan-ly-bds/danh-sach-tin-bds')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getEmploy(agency_id) {
            this.startLoading();
            await this.$axios.get('/auth/getListUser',{
                params: {
                    agency_id: agency_id,
                    user_name: '',
                    page: 1,
                    per_page: 1000,
                },
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.listEmploy = res.data.result.filter(item => item.is_delete != 1)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateSale(item) {
            event.preventDefault()
            const data = {
                id: this.id,
                sale: item.user_id
            }
            this.startLoading();
            await this.$axios.put('/real_estate/updateRealEstateSale',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Cập nhật nhân viên sale thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateBroker(item) {
            event.preventDefault()
            const data = {
                id: this.id,
                broker: item.user_id
            }
            this.startLoading();
            await this.$axios.put('/real_estate/updateRealEstateBroker',data,{
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Cập nhật nhân viên môi giới thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateContract() {
            let formData = new FormData();
            if(event.target.files) {
                for(let i = 0; i < event.target.files.length; i++) {
                    formData.append("file", event.target.files[i]);
                }
            }
            formData.append('id', this.id)
            this.startLoading();
            await this.$axios.put('/real_estate/updateBrokerageContract', formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Cập nhật hợp đồng môi giới thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async addStaff() {
            const data = {
                id: this.id,
                commission: this.dataDetail.commission
            }
            this.startLoading();
            await this.$axios.put('/real_estate/addStaffRealEstate', data,
                {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thêm quản lý thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateCommission() {
            const data = {
                id: this.id,
                commission: this.dataDetail.commission,
                sale_commission: this.dataDetail.sale_commission,
            }
            this.startLoading();
            await this.$axios.put('/real_estate/updateRealEstateCommission', data,
                {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thay đổi % hoa hồng thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateContractType(type) {
            const data = {
                id: this.id,
                contract_type: type
            }
            this.startLoading();
            await this.$axios.put('/real_estate/updateContractType', data,
                {
                headers: {
                    'x-access-token': this.getToken()
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thay đổi loại hợp đồng thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        calculateCommission(type) {
            if(type == 'commission') {
                const cleanedValue = this.price_commission.replace(/[^\d]/g, '');
                const number = parseInt(cleanedValue, 10);
                this.price_commission = number.toLocaleString('vi');
                if(this.price_commission == 'NaN') {
                    this.price_commission = ''
                }
    
                if(!this.price_commission) {
                    this.dataDetail.commission = ''
                } else if(this.dataDetail.unit_id == 2) {
                    const value = this.price_commission.replace(/[^\d]/g, '') / this.dataDetail.price * 100
                    if(this.dataDetail.price % value == 0) {
                        this.dataDetail.commission = value
                    } else {
                        this.dataDetail.commission = value.toFixed(3)
                    }
                } else if(this.dataDetail.unit_id == 4) {
                    const value = this.price_commission.replace(/[^\d]/g, '') / (this.dataDetail.price * this.dataDetail.acreage) * 100
                    if((this.dataDetail.price * this.dataDetail.acreage) % value == 0) {
                        this.dataDetail.commission = value
                    } else {
                        this.dataDetail.commission = value.toFixed(3)
                    }
                }
            } else {
                this.dataDetail.commission = this.dataDetail.commission.replace(/[^\d.]/g, '')
                if(!this.dataDetail.commission) {
                    this.price_commission = ''
                } else if(this.dataDetail.unit_id == 2){
                    this.price_commission = ((this.dataDetail.price * this.dataDetail.commission)/100).toLocaleString('vi')
                } else if(this.dataDetail.unit_id == 4) {
                    this.price_commission = (((this.dataDetail.price * this.dataDetail.acreage) * this.dataDetail.commission)/100).toLocaleString('vi')
                }
            }
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .wp-btn button {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        padding: 8.5px 14px;
        border-radius: 15px;
        border: 1px solid #515151;
        background: none;
    }

    .wp-btn button.btn-rej {
        margin: 0 16px;
    }

    .wp-btn button.btn_approve {
        color: #FFF;
        background: #F58E16;
        border: none;
    }

    .wp-btn button.btn_approve:disabled {
        background: #F9CB95;
    }

    .wp-btn button.btn_delete {
        color: #EE3439;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #EE3439;
        margin-left: 16px;
    }

    .dropdown button {
        width: 100%;
        height: 50px;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 0 4px;
        color: #515151;
        font-weight: 500;
        letter-spacing: 0.07px;
        border: none;
        margin-bottom: 20px;
    }

    .dropdown button.agency {
        border-radius: 20px;
        background: #F4F4F4;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        padding-left: 13px;
        box-shadow: none;
        height: 40px;
        margin: 5px 0 15px;
    }

    .dropdown--agency .dropdown-toggle:focus,
    .dropdown--agency .dropdown-toggle:active {
        color: #515151 !important;
    }

    .dropdown-toggle:active {
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 100%;
        padding: 10px 12px 15px;
        margin-top: 8px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .dropdown-toggle::after {
        right: 26px;
        position: absolute;
        top: 21px;
        margin-left: 2.255em;
        color: #515151 !important;
    }

    .law-info, .info, .pay-info, .manager-info {
        margin-bottom: 20px;
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .law-info h5, .info h5, .pay-info h5, .manager-info h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .law-info p, .info p, .pay-info p, .manager-info p {
        margin: 0;
        font-size: 14px;
        color: #686868;
    }

    .pay-info {
        margin-bottom: 0;
    }

    .user {
        border-radius: 69px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 6px;
        margin-bottom: 10px;
    }

    .user--manager {
        box-shadow: none;
        margin-bottom: 15px;
    }

    .user.user--manager img {
        width: 67px;
        height: 67px;
        min-width: 67px;
    }

    .user img {
        border-radius: 54px;
        width: 54px;
        height: 54px;
        min-width: 54px;
        margin-right: 10px;
        object-fit: cover;
    }

    .user p:last-child {
        font-size: 18px;
        color: #515151;
        font-weight: 500;
    }

    p.info_txt {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 9px 13px;
        margin-bottom: 10px;
    }

    .law-info ul, .list_file {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .law-info ul li, .list_file li {
        border-radius: 20px;
        background: #FCE3C5;
        padding: 7.5px 15px;
        margin-bottom: 10px;
    }

    .law-info ul li:last-child, .list_file li:last-child {
        margin-bottom: 0;
    }

    .law-info ul li a, .list_file li a {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        display: block;
        overflow: hidden;
    }

    .law-info ul li a img, .list_file li a img {
        margin-right: 11px;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        height: 100%;
        margin-right: 20px;
    }

    .detail h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    .status_new {
        color: #686868;
        font-size: 14px;
        font-weight: 500;
        margin-right: 21px;
    }

    .status_new > span {
        border-radius: 15px;
        background: rgba(0, 181, 105, 0.20);
        color: #00B569;
        padding: 4.5px 19px;
        margin-left: 5px;
    }

    .status_new > span.expired {
        color: #EE3439;
        background: rgba(238, 52, 57, 0.20);
    }

    .show_web {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        margin-right: 16px;
    }

    .show_web .el-switch {
        margin-left: 8px;
    }

    .txt--org {
        color: #F58E16 !important;
    }

    .txt--red {
        color: #EE3439 !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .item-info {
        font-weight: 600;
        border-radius: 27px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        padding: 11px 19px;
        color: #515151;
        text-align: center;
        margin-bottom: 15px;
    }

    .item-info:last-child {
        margin-bottom: 0;
    }

    .item-info span {
        min-width: 80%;
        display: inline-block;
    }

    .dialog_del p.ttl {
        font-size: 20.96px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .dialog_del p {
        color: #444;
    }

    .dialog_del button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #EE3439;
    }

    .dialog_del button.btn_add {
        color: #FFF;
        background: #EE3439;
        border: none;
        margin-left: 13px;
    }

    .btn_file {
        cursor: pointer;
        margin: 3px 0 12px;
    }

    .btn_file button {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 180%;
        border-radius: 15px;
        background: #444;
        padding: 3.5px 10px;
        margin-right: 7px;
    }

    .btn_file span {
        color: #515151;
        font-size: 16px;
        font-weight: 400;
        line-height: 180%;
        border-radius: 15px;
        background: #F6F6F6;
        padding: 3.5px 10px;
        display: inline-block;
        width: 100%;
        max-width: 275px;
    }

    .add_user.dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
    }

    .add_user.dropdown-menu li {
        height: unset;
        background: transparent;
        text-align: left;
    }

    .add_user.dropdown-menu li a {
        display: flex;
        align-items: center;
    }

    .add_user.dropdown-menu li a img {
        width: 67px;
        height: 67px;
        border-radius: 33.5px;
        margin-right: 20px;
    }

    .add_user.dropdown-menu li a p {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .add_user.dropdown-menu li a p:last-child {
        color: #ABABAB;
        font-size: 14px;
        margin-bottom: 0;
    }

    .view_bds {
        color: #515151;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.16px;
        border-radius: 20px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        width: 100%;
        text-align: center;
        padding: 10px 20px;
        margin-top: 20px;
    }

    button.confirm {
        border-radius: 20px;
        background: #EE3439;
        color: #FFF;
        text-align: center;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.16px;
        width: 100%;
        padding: 10px 20px;
        margin-top: 20px;
    }

    button.confirm:disabled {
        opacity: 0.7;
    }

    .wp-btn-type {
        /* min-width: 320px; */
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        border-radius: 55px;
        border: 1px solid #B4B4B4;
        padding: 0 4px;
        margin: 0 10px 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-btn-type button {
        width: 49%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn-type button.active {
        border-radius: 41px;
        background: #FCE3C5;
        color: #F58E16;
    }

    .bid {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 15px;
        width: 100%;
    }

    .bid button {
        border: none;
        outline: none;
        border-radius: 80px;
        background: #F58E16;
        padding: 10px 14px;
        color: #FFF;
        text-align: center;
        font-family: 'Public Sans', sans-serif;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.08px;
        order: 2;
        width: 100%;
    }

    .bid button img {
        width: 29px;
        height: 29px;
        margin-right: 15px;
    }

    .bid span {
        color: #515151;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.08px;
        margin-bottom: 5px;
        margin-right: 17px;
    }
    .extend button {
        border: none;
        outline: none;
        border-radius: 20px;
        background: #F58E16;
        padding: 10px 14px;
        color: #FFF;
        text-align: center;
        font-family: 'Public Sans', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.08px;
        order: 2;
        width: 100%;
    }

    .wp-user {
        max-height: 400px;
        overflow: auto;
    }

    .wp-user::-webkit-scrollbar {
        width: 0;
    }

    .disabled--block {
        pointer-events: none;
        opacity: 0.5;
    }

    @media (max-width: 1400px) {
        .user.user--manager img {
            width: 54px;
            height: 54px;
            min-width: 54px;
        }
    }

    @media (max-width: 992px) {
        .breadcrumb {
            display: none;
        }

        .wp-btn {
            margin-bottom: 20px;
        }
    }
</style>

<style>
    .add_user .el-input__wrapper {
        padding-left: 15px !important;
    }

    .input_com .el-input__wrapper {
        padding: 0 10px !important;
        background: #F4F4F4;
        margin-bottom: 10px;
        color: #515151;
        font-size: 15px;
        border-radius: 20px !important;
        border: none !important;
    }

    .el-notification__content {
        text-align: left !important;
    }

    @media (max-width: 1500px) {
        .dropdown-toggle::after {
            display: none !important;
        }
    }
</style>